.product-info-container {
  padding: 80px 150px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  background-color: var(--t);
  /* color: #fff; */
}
.product-info-container input {
  color: #000;
}
.product-info-img-slider {
  width: 7%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-info-img-slider img {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  object-fit: cover;
}
.product-info-img {
  width: 30%;
  height: 350px;
}
.product-info-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-info-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}
.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 50%;
}
.player-tag {
  padding: 10px;
  width: 50%;
  outline: transparent;
  border: 1px solid #fff;
  margin-top: 10px;
}
.product-info-content h3 {
  text-transform: uppercase;
}
.add-to-cart-btn {
  width: 50%;
  background-color: var(--p);
  color: #fff;
  padding: 10px;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
}
.safe-checkout-img {
  width: 35%;
  margin-top: 10px;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.tab-btns-desc {
  display: flex;
}
.tab-btns-desc .tab {
  padding: 10px;
  text-align: center;
  width: 50%;
  background-color: #eee;
  cursor: pointer;
}
.tab-btns-desc .tab.active {
  background-color: #ebef29;
}
.product-info-details {
  margin-top: 20px;
  min-height: 250px;
}
.p-amount {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.amount {
  width: 48%;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    background-color: var(--p);
  }
}
.amount.active {
  background-color: var(--p);
}
.p-check-btn {
  outline: transparent;
  border: transparent;
  background-color: var(--p);
  padding: 10px 20px;
  margin-left: 5px;
}
.product-desc {
  padding: 50px 200px;
  background-color: var(--p);
  color: #fff;
  h5 {
    font-weight: bold;
    color: var(--s);
  }
}
.product-desc p {
  white-space: pre-wrap;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
@media screen and (max-width: 992px) {
  .product-info-container {
    padding: 50px 20px;
  }
  .product-info-img-slider {
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
  .product-info-img-slider img {
    width: 20%;
    margin-right: 10px;
  }
  .product-info-img {
    width: 100%;
  }
  .product-info-content {
    width: 100%;
    padding-left: 0;
  }
  .select-brand select {
    width: 100%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
  .safe-checkout-img {
    width: 100%;
  }
  .product-info-content select,
  .player-tag {
    width: 100%;
  }
  .product-desc {
    padding: 50px 20px;
  }
}
