.subscriber-form {
  background-color: #282828;
  padding: 50px 20px;
  text-align: center;
}
.subscriber-form h1 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 30px;
}
.subscriber-form h5 {
  font-weight: 400;
  margin-top: 10px;
}
.subcribe-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
.subcribe-input {
  padding: 15px;
  width: 500px;
}
.subcribe-input-btn {
  background-color: #282828;
  color: #fff;
  padding: 15px 30px;
  outline: transparent;
  border: 1px solid #fff;
  margin-left: 5px;
  transition: 0.3s all ease;
}
.subcribe-input-btn:hover {
  background-color: #fff;
  color: #333;
}

.subcribe-success {
  background-color: #def4e9;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}
@media screen and (max-width: 992px) {
  .subscriber-form h1 {
    font-size: 25px;
  }
  .subscriber-form h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 684px) {
  .subcribe-input-btn {
    margin-top: 10px;
  }
}
