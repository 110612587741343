.wallet-dash {
  display: flex;
  padding: 30px 0px;
  padding-top: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.wallet-dash-card {
  width: 30%;
  padding: 20px;
  background: linear-gradient(to right, #fff, #eee);
  border: 1px solid #000;
  cursor: pointer;
}
.add-money {
  display: flex;
  width: 100%;
  gap: 50px;
}
.bar-img {
  width: 50%;
}
.txn-details {
  width: 50%;
}
.bar-img img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .wallet-dash-card {
    width: 100%;
  }
  .add-money {
    padding: 50px 10px;
    flex-wrap: wrap;
  }
  .bar-img {
    width: 100%;
  }
  .txn-details {
    width: 100%;
  }
}
