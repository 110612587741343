.hero-slider {
  padding: 100px;
  overflow: hidden;
  background-color: var(--t);
}
.hero-img {
  padding: 10px;
}
.hero-slider img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 992px) {
  .hero-container {
    padding: 0;
  }
  .hero-slider {
    padding: 50px 20px;
  }
}
