.games-container {
  padding: 70px 20px;
  background-color: var(--s);
  color: #333;
}
.games-container h1 {
  color: #fff;
  font-weight: bold;
}
.game-product {
  padding: 0 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.game {
  width: 23%;
  padding: 15px;
  background-color: #fff;
  text-align: center;
  color: #000;
  cursor: pointer;
  border-radius: 15px;
}
.game img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
}
.game h5 {
  margin: 0;
  font-size: 15px;
}
@media screen and (max-width: 992px) {
  .game-product {
    padding: 10px;
  }
  .game {
    width: 30%;
    padding: 10px;
    background-color: #fff;
    text-align: center;
    color: #000;
    cursor: pointer;
  }
}
@media screen and (max-width: 600px) {
  .game {
    width: 28%;
  }
  .game h5 {
    font-size: 10px;
  }
  .game h6 {
    font-size: 10px;
  }
}
